.cardContainer {
  width: 90%;
  height: 134px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  background: #ffffff;
  border: 1px solid #dddddd;
  // outline: 1px solid #dddddd;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  z-index: 10;
  :focus::before {
    content: "";
    position: absolute;
    top: -2px; // border: 2px + offset: 1px
    right: -2px; // border: 2px + offset: 1px
    bottom: -2px; // border: 2px + offset: 1px
    left: -2px; // border: 2px + offset: 1px
    border: 1px solid #dddddd;
    border-radius: 7px; // border—radius: 6px + offset: 1px
  }
}

.cardContainerSelected {
  width: 90%;
  height: 134px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  cursor: pointer;
  background: #ffffff;
  transition: outline 0.2s linear;
  border: 2px solid #FFC719;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  box-sizing: border-box;
  position: relative;
  z-index: 10;
  :focus::before {
    content: "";
    position: absolute;
    top: -3px; // border: 3px + offset: 1px
    right: -3px; // border: 3px + offset: 1px
    bottom: -3px; // border: 3px + offset: 1px
    left: -3px; // border: 2px + offset: 1px
    border: 2px solid #FFC719;
    border-radius: 7px; // border—radius: 6px + offset: 1px
  }
}
.image {
  height: 134px;
  width: 35%;
  border-radius: 10px 0px 0px 10px;
  // margin-right: 5px;
}

.cardContainerSelected .image {
  height: 130px;
}
.textWrapper {
  // margin-left: 5px;
  padding: 5px;
  max-width: 60%;
  margin-left: 5px;
}
.title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 138%;
  display: flex;
  align-items: center;
  justify-self: flex-start;
  letter-spacing: 0.0075em;
  color: #000;
}
.description {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #000;
}
.checkContainer {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.unCheck {
  background: #FFC719;
  opacity: 0.5;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin: 25px;
}
.check {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFC719;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin: 25px;
  img {
    height: 9.9px;
    width: 13.2px;
  }
}
.imageContainer {
  display: flex;
  align-items: center;
  flex: 2;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 0;
}
// .image {
//   height: 100%;
//   min-height: 95px;
//   border-radius: 0px 6px 6px 0px;
// }

.timer {
  position: absolute;
  min-width: 71px;
  width: auto;
  height: 19px;
  right: -1px;
  bottom: -1px;
  background: #28353c;
  border-radius: 6px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.timerSelected {
  position: absolute;
  min-width: 71px;
  width: auto;
  height: 19px;
  right: -2px;
  bottom: -2px;
  border-radius: 6px 0px;
  background: #e37a3e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}
.timeIcon {
  display: flex;
  width: 12px;
  height: 14px;
  margin-right: 5px;
  margin-left: 5px;
}
.timeText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 138%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;
  color: #28353c;
}
.renewalWithImage {
  width: 65% !important;
}
.renewal {
  position: absolute;
  width: calc(100% - 60px);
  height: 25px;
  top: 0px;
  right: 0px;
  background-color: #dddddd;
  border-radius: 0px 10px 0px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    margin-left: 5px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 138%;
    /* identical to box height, or 17px */

    display: flex;
    align-items: center;
    letter-spacing: 0.0075em;

    /* KS_FFF */

    color: #28353c;
  }
}

.white {
  color: #ffffff !important;
}

@media (min-width: 750px) {
  .cardContainer {
    width: 90%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    /* KS_FFF */

    background: #ffffff;
    /* KS_E9EBEC */

    border: 1px solid #e9ebec;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .cardContainerSelected {
    width: 90%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    cursor: pointer;
    background: #ffffff;
    border: 4px solid #44c032;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    border-radius: 6px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .cardContainerSelected .renewal {
    border-radius: 0;
  }

  .image {
    height: 199px !important;
    width: 35%;
    object-fit: cover;
    border-radius: 3px 0px 0px 3px;
    z-index: -1;
    margin-left: -1px;
  }

  .cardContainerSelected .image {
    height: 192px !important;
  }
}
