.Container {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  background-color: #dfe0df;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 83px);
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  background-color: #ffffff;
  height: 32px;
  img {
    width: 65px;
    margin-right: 10px;
  }
  span {
    color: #c2ced9;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    margin-right: 10px;
    margin-top: 3px;
  }
}

.siteSpecificInductionWrapper {
  h2 {
    //styleName: Main Heading Bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 4vh;
    font-weight: 700;
    line-height: 4.2vh;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
  }
  h3 {
    //styleName: Main Heading Regular;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 4vh;
    line-height: 4.2vh;
    font-weight: 400;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
    margin-bottom: 2vh;
  }
  .text {
    //styleName: Intro Bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.5vh;
    line-height: 2.7vh;
    text-align: center;
    padding-bottom: 16px !important;
    color: #000000;
  }
  .textBold {
    //styleName: Intro Bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.5vh;
    line-height: 2.7vh;
    text-align: center;
    padding-bottom: 16px !important;
    font-weight: 700;
    color: #000000;
  }

  width: 90%;
  min-height: calc(100vh - 200px);
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .startSectionWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 7vh;
      margin-bottom: 20px;
    }
  }

  .middleSectionWrapper {
    display: flex;
    flex: 1;
    pointer-events: none;

    .qrWrapper {
      background-image: url("/assets/tincan/rectangle.png");
      background-size: contain;

      margin: 20px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      width: 30vh;
      height: 30vh;
      padding: 10px;
      @media (max-width: 750px) {
        width: 25vh;
        height: 25vh;
      }
    }
  }
}
