.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 100px;
}
.wrapper {
  padding: 18px;
  width: 100%;
}

.title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 138%;
  text-align: center;
  letter-spacing: 0.0075em;
  margin: 20px 0px 40px 0px;
  color: #000;
}
.personaItemWrapper .personaItem:first-child {
  transition: 300ms;
  transform: translateX(150px);
  transition-timing-function: ease;
}

.personaItemWrapper .personaItem:nth-child(2) {
  transition: 400ms;
  transform: translateX(150px);
  transition-timing-function: ease;
}

.personaItemWrapper .personaItem:nth-child(3) {
  transition: 500ms;
  transform: translateX(150px);
  transition-timing-function: ease;
}

.personaItemWrapperLoaded .personaItem:first-child {
  transition: 300ms;
  transform: translateX(0);
  transition-timing-function: ease;
}

.personaItemWrapperLoaded .personaItem:nth-child(2) {
  transition: 400ms;
  transform: translateX(0);
  transition-timing-function: ease;
}

.personaItemWrapperLoaded .personaItem:nth-child(3) {
  transition: 500ms;
  transform: translateX(0);
  transition-timing-function: ease;
}

.personaItem {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 43px;
}

.personaItemDisabled {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 43px;
  opacity: 0.5;
}

.checkbox {
  background: #ffffff;
  /* KS_E9EBEC */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9ebec;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  width: 40px;
  height: 40px;
  align-items: center;
  border-radius: 40px;
  margin: 18px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}
.personaTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 138%;
  /* identical to box height, or 25px */
  margin-bottom: 17px;
  letter-spacing: 0.0075em;

  /* KS_0F1619 */

  color: #000;
}
.personaDesc {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  letter-spacing: 0.0075em;

  /* 444444 */

  color: #444444;
}

.icon {
  margin-left: 20px;
  width: 80px;
  margin-top: -10px;
}
