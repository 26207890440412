.headingContainer {
  position: relative;
  width: 100%;
  margin-top: 0;
  // max-height: 172px;
  z-index: 2;
  display: flex;
  height: initial;
  min-height: 130px;
  margin-bottom: 10px;
  background-color: #f8f8f8;
}
.mn100 {
  min-height: 95px !important;
}

.headingWrapper {
  /*position: absolute;*/
  top: 0;
  left: 0;
  width: 90%;
  margin: 0 auto;
  height: inherit;
  background: #f8f8f8;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.onAnimationEnd {
  background: transparent !important;
  box-shadow: none;
}
.none {
  display: none !important;
}
.headingTextWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 3px;
  padding: 3px;
}
.arrowWrapper {
  display: flex;
  width: 56px;
  min-width: 56px !important;
  height: 56px;
  border-radius: 56px;
  // background: #ffffff;
  // box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  justify-content: center;
  align-items: center;
  // path {
  //   fill: #44c032 !important;
  // }
}
.softArrowWrapper {
  animation: arrowAppear 1s;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  path {
    fill: #44c032 !important;
  }
}
.greenArrowWrapper {
  animation: arrowAppear 1s;
  background: #44c032;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  path {
    fill: #ffffff !important;
  }
}
.counterWrapper {
  display: flex;
  width: 56px;
  height: 56px;
  min-width: 56px !important;
}
.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 138%;
  text-align: center;
  letter-spacing: 0.0075em;
  text-align: center;
  margin-bottom: 10px;
  color: #000;
}
.description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 138%;
  /* or 22px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* KS_FFF */

  color: #000;
}

.animation {
  animation: headingUp 4s;
}

@keyframes headingUp {
  0% {
    top: 0px;
  }
  50% {
    top: 0px;
  }
  100% {
    top: -150px;
  }
}
@keyframes arrowAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
