@import "../../styles/variables";

.container {
}
.wrapper {
  padding: 20px;
  height: calc(100vh - 80px);
}

.FormWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.SelectContainer {
  width: 100%;
  margin-bottom: 51px;
  position: relative;
}

.BlockLabel {
  display: block;
  font-family: Open Sans;
  font-style: normal;
  font-size: 14px;
  line-height: 55px;
  color: map-get($colors, c1);
  margin-left: 20px;
}

.title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 138%;
  /* or 30px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* KS_0F1619 */
  margin-bottom: 60px;
  color: #000;
}
.personaItem {
  align-items: center;
  margin-bottom: 20%;
}
.languageImage {
  object-fit: cover;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  border: 5px solid #e9ebec;
  object-position: center;
}
.checkbox {
  background: #ffffff;
  /* KS_E9EBEC */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9ebec;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  width: 40px;
  height: 40px;
  align-items: center;
  border-radius: 40px;
  margin: 18px;
}

.personaTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 138%;
  /* identical to box height, or 25px */
  letter-spacing: 0.0075em;

  /* KS_0F1619 */

  color: #28353c;
}
.FirstCol,
.SecondCol,
.FirstColVisible,
.SecondColVisible {
  width: 50%;
}

.FirstCol .personaItem {
  transform: translateX(-100vw);
}

.SecondCol .personaItem {
  transform: translateX(100vw);
}

.FirstColVisible .personaItem:first-child {
  transform: translateX(0);
  transition: 300ms;
}

.FirstColVisible .personaItem:nth-child(2) {
  transform: translateX(0);
  transition: 400ms;
}

.FirstColVisible .personaItem:nth-child(3) {
  transform: translateX(0);
  transition: 500ms;
}

.SecondColVisible .personaItem:first-child {
  transform: translateX(0);
  transition: 300ms;
}

.SecondColVisible .personaItem:nth-child(2) {
  transform: translateX(0);
  transition: 400ms;
}

.SecondColVisible .personaItem:nth-child(3) {
  transform: translateX(0);
  transition: 500ms;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.textWrapperDisabled {
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 300ms;
}
.textWrapperSelected {
  transform: scale(1.2) !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  transition: 300ms !important;
}
