.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 199999999;
  .modalWrapper {
    display: flex;
    width: 342px;
    height: 454px;
    background: #ffffff;
    border: 1px solid #f2f2f2;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 40px;
    position: fixed;
    top: calc(calc(100vh - 454px) / 2);
    flex-direction: column;
    .title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 138%;
      letter-spacing: 0.0075em;
      color: #000;
      margin-bottom: 47px;
    }

    .description {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 138%;
      letter-spacing: 0.0075em;
      color: #000;
      margin-bottom: 70px;
    }
  }
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ligthButton {
    width: 113px;
    height: 44px;
    border: 1px solid #28353c;
    border-radius: 22px;
    background-color: #ffffff;
    outline: none;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 138%;
    text-align: center;
    letter-spacing: 0.0075em;
    color: #000;
    cursor: pointer;
    &:hover {
      outline: none;
      text-decoration: normal !important;
      list-style: none;
      opacity: 0.5;
    }
    &:focus {
      outline: none;
      text-decoration: normal !important;
      list-style: none;
    }
  }

  .redButton {
    width: 113px;
    height: 44px;
    background: #000;
    border-radius: 22px;
    color: #fff;
    outline: none;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 138%;
    text-align: center;
    letter-spacing: 0.0075em;
    border: none;
    cursor: pointer;

    &:hover {
      outline: none;
      text-decoration: normal !important;
      list-style: none;
      border: none;
      opacity: 0.5;
    }
    &:focus {
      outline: none;
      text-decoration: normal !important;
      list-style: none;
      border: none;
    }
  }
}

.modalBackground {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
  background-color: #0f1619;
  opacity: 0.5;
}

.closeIconWrapper {
  width: 44px;
  height: 44px;
  background: #ffffff;
  border: 5px solid #fff100;
  border-radius: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -11px;
  top: -11px;
  &:hover {
    opacity: 0.9;
  }
  cursor: pointer;
}

.hidden {
  display: none;
}
