.Container {
  width: 100%;
  position: relative;
  padding: 0 0.9375rem;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans";
}
.Title {
  font-size: 1rem;
  line-height: 138%;
  margin-bottom: 1rem;
  letter-spacing: 0.0075em;
  color: #000;
}
.Header {
  font-size: 1.125rem;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #000;
}
.Image {
  width: 100%;
  height: auto;
  margin-bottom: 3.125rem;
  /*box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));*/
}
.Content {
  font-size: 0.875rem;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #000;
  margin-bottom: 1.25rem;
}

@media (min-width: 768px) {
  .Image {
    width: 100%;
  }
}
