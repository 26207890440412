.Container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 140px);
  width: 100%;
  height: auto;
  margin-bottom: 100px;
}
.TextContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
}
.Heading {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 138%;
  /* or 30px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* KS_0F1619 */

  color: #000;
  padding-top: 27px;
  margin-bottom: 17px;
}
.Description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* KS_28353C */

  color: #28353c;
}

.inductionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 60px;
  min-height: calc(100vh - 200px);
  height: 100%;
  // max-width: 70%;
}

@media (min-width: 750px) {
  .inductionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 60px;
    min-width: 500px;
    align-self: center;
    min-height: calc(100vh - 200px);
    height: 100%;
  }
}

.Link {
  width: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
}
