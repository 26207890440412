.ItemCard {
  width: 100%;
  height: 120px;
  background: #ffffff;
  /* F8F8F8 */
  margin-top: 20px;
  padding-top: 36px;
  border: 1px solid #f8f8f8;
  box-sizing: border-box;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  position: relative;
  padding-right: 10px;
}
.ItemCardCustom, .ItemCardCustomUnChecked {
  width: 100%;
  height: 120px;
  background: #ffffff;
  /* F8F8F8 */
  margin-top: 20px;
  padding-top: 30px;
  border: 4px solid rgba(68, 192, 50, 0.20);
  box-sizing: border-box;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  position: relative;
}

.ItemCardCustomUnChecked
{
  border: 4px solid rgba(226, 108, 130, 0.20);
}


.ItemChargeUP
{
  background-color: #FFF100;
  color: #000;
  padding: 4px;
  margin-bottom: 4px;
  display: inline-block;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;

}

.ItemText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 138%;
  /* or 25px */

  text-align: left;
  padding-left: 60px;
  letter-spacing: 0.0075em;
  display: block;
  /* KS_0F1619 */

  color: #000;
}
.ItemIcon
{
  position: absolute;
  bottom: 0;
  right: 4px;
}
.ItemIcon img
{
  width: 32px;
}
.ItemDocumentUploadedText
{
  position: absolute;
  bottom:6px;
  left: 0;
  width: 100%;
  text-align: center;
  color: #44c032;
  font-size: 16px;
}
.Checkbox {
  position: absolute;
  left: 0;
  top: 32px;
  width: 52px;
  height: 52px;
  background: #f2f2f2;
  padding: 10px;
  /* FFF */
  border-radius: 52px;
  border: 5px solid #ffffff;
  box-sizing: border-box;
}

.CheckboxCustomUnChecked
{
  position: absolute;
  left: 0;
  top: 32px;
  width: 52px;
  height: 52px;
  background: #E26C82;
  padding: 10px;
  /* FFF */
  border-radius: 52px;
  border: 5px solid #ffffff;
  box-sizing: border-box;
}

.ItemCheckboxWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.CorrectIconImage {
  width: 100%;
  height: 100%;
}

@media (max-width: 760px) {
  .ItemCard {
    width: 100%;
    height: 100px;
    background: #ffffff;
    /* F8F8F8 */
    padding-top: 0px;

    border: 1px solid #f8f8f8;
    box-sizing: border-box;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-right: 10px;
  }
  .ItemCardCustom, .ItemCardCustomUnChecked {
    width: 100%;
    height: 100px;
    background: #ffffff;
    /* F8F8F8 */
    padding-top: 0px;
    border: 4px solid rgba(68, 192, 50, 0.20);
    box-sizing: border-box;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-right: 10px;
  }

  .ItemCardCustomUnChecked
  {
    border: 4px solid rgba(226, 108, 130, 0.20);
  }

  .ItemText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 138%;
    /* or 25px */
    letter-spacing: 0.0075em;

    /* KS_0F1619 */

    color: #000;
  }

  .Checkbox,.CheckboxCustomUnChecked {
    position: absolute;
    top: 20px;
  }

}
