.FooterContainer {
  width: 100%;
  height: 60px;
  background: #ffffff;
  background-color: #ffffff;
  box-shadow: 0px -16px 40px rgba(0, 0, 0, 0.1);
  position: fixed;
  transition: 300ms;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  animation: toUp 1s alternate;
}

@keyframes toUp {
  from {
    bottom: -80px;
    right: 0;
  }

  to {
    bottom: 0px;
    right: 0;
  }
}
@keyframes toDown {
  from {
    bottom: -80px;
  }

  to {
    bottom: 0px;
  }
}

.FooterContainerHidden {
  display: none;
  animation: toDown 1s alternate;
}

.FooterWrapper {
  z-index: 1;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  box-shadow: none;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .FooterWrapper {
    z-index: 1;
    width: 100%;
    height: 80px;
    background-color: #ffffff;
    box-shadow: 0px -16px 40px rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    left: 0;
    // margin-left: -150%;
    animation: toUp 1s alternate;
  }
  .FooterContainer {
    z-index: 1;
    width: 687px;
    max-width: 687px;
    height: 80px;
    background: #ffffff;
    background-color: #ffffff;
    position: relative;
    box-shadow: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    animation: unset;
  }
  .RulesFooter {
    z-index: 1;
    margin-left: 0% !important;
  }
}
