@import "../../styles/variables";

.Container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 100px;
}
.TextContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
}
.Heading {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  letter-spacing: 4.17232e-9px;
  margin-bottom: 24px;
  color: map-get($colors, c3);
}
.Description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1.58946e-9px;
  color: map-get($colors, c8);
}
.Link {
  width: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
}
.transcriptContainer {
  background: #ecf9eb;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.25);
  padding: 20px;
  display: none;
  h3 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 138%;
    /* identical to box height, or 28px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* KS_0F1619 */

    color: #000;
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    letter-spacing: 0.0075em;

    /* KS_0F1619 */

    color: #000;
  }
  button {
    background: #ffffff;
    border: 1px solid #44c032;
    border-radius: 6px;
    height: 44px;
    width: 100%;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 138%;
    /* identical to box height, or 22px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* KS_44C032 */

    color: #44c032;
  }
}

.videoSettingsContainer {
  width: 90%;
  margin: 0 auto;
  /*display: flex;*/
  display: none;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 33px;
  margin-top: 33px;
  .videoHeader {
    background: #ecf9eb;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 6px 6px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    height: 44px;
    width: 100%;
    span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 138%;
      letter-spacing: 0.0075em;
      color: #113148;
    }
  }
  .subtitleCol {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 20px;
      height: 20x;
      display: flex;
      margin-left: 40px;
    }
  }
  .videoCol {
    display: flex;
    height: 45px;
    border-bottom: 0.5px solid #e9ebec;
    border-left: 0.5px solid #e9ebec;
    border-right: 0.5px solid #e9ebec;

    padding: 14px 0px 12px 18px;
    justify-content: space-between;
    align-items: center;
  }

  .selectCol {
    display: flex;
    height: 20px;
  }
}

.turn {
  transform: rotate(180deg);
}
