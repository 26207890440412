@import "../../styles/_variables.scss";
.Title {
  font-family: Open Sans;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 24px;
  text-align: center;
  color: #000000;
}
.completeImage {
  height: 20vh;
  margin: 20px auto;
}
.Content {
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 30px;
  color: #000000;
}

.Link {
  width: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
}

.animationWrapper {
  width: 100%;
  padding-bottom: 30px;
}

.AllChaptersCompleteContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: calc(100vh - 140px);
}

.ContentContainer {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  margin-bottom: 50px;
  p {
    text-align: left !important;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    max-width: 100%;
    /* or 19px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* KS_28353C */

    color: #28353c;
  }
}

.SubContentWrapper {
  text-align: center;
  margin-top: 10vh;
  align-items: center;
  display: flex;
  p {
    margin: 0 auto;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 138%;
    /* or 48px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* KS_28353C */

    color: #28353c;
  }
}
