@import "../../../styles/variables.scss";
.Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  [data-id="start"][contentEditable="false"]:empty:not(:focus):before {
    content: attr(data-text);
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0075em;
    color: #44c032;
  }
}

.portal {
  position: absolute;
  top: 84px;
  left: 0;
  width: 100%;
  padding: 0;
  animation: headingUp 4s;
  @media (min-width: 768px) {
    position: absolute;
    top: 84px;
    left: calc(calc(100vw - 760px) / 2);
    width: 750px;
    padding: 0;
  }
}

.cardCategoryText {
  text-align: start !important;
}
@keyframes headingUp {
  0% {
    top: 250px;
  }
  50% {
    top: 250px;
  }
  100% {
    top: 150px;
  }
}

.Category {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 138%;
  /* identical to box height, or 25px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* 666666 */

  color: #666666;
  text-align: center;
  padding: 0 15px;
  margin-bottom: 0;
  margin-top: 32px;
}
.Title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 138%;
  /* identical to box height, or 25px */

  letter-spacing: 0.0075em;

  /* KS_0F1619 */

  color: #000;
  text-align: center;
  margin-bottom: 13px;
}
.Content {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  letter-spacing: 0.0075em;

  /* KS_28353C */

  color: #000;
  text-align: start;
  margin-bottom: 35px;
  margin-top: 40px;
  margin-left: 15px;
}

.icon {
  width: auto;
  height: 32px;
  margin-top: -12px;
  margin-bottom: 5px;
}

.Question {
  @media (max-width: 321px) {
    width: 140px;
  }
  width: 165px;
  min-height: 90px;
  padding: 5px;
  height: auto;
  border: 1px solid #e9ebec;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  background: #000000;
}
.QuestionDescription {
  width: 100%;
  height: auto;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 5px;
}
.TitleDescription {
  width: 100%;
  padding-top: 10px;
  height: auto;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0.0075em;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.QuestionFeedback {
  width: 100%;
  height: auto;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: map-get($colors, c19);
  padding: 10px;
}
.Keyword {
  align-self: flex-start;
  width: 100%;
  height: 48px;
  padding: 0 9px;
  background: linear-gradient(180deg, #3c4d56 0%, #28353c 100%);
  border-radius: 6px 6px 30px 0px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;
  color: #ffffff;
  text-align: center;
}
.DotsContainerPreview {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dotsPreview {
  height: 4px;
  width: 10px;
  color: #323232 !important;
  span {
    color: #323232 !important;
  }
}
.DotsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: -10px;
}
.dots {
  height: 4px;
  width: 10px;
  color: #ffffff !important;
  span {
    color: #ffffff !important;
  }
}

.SuccessBackground {
  background-color: rgba(68, 192, 50, 0.1) !important;
}
.ErrorBackground {
  background-color: rgba(226, 108, 130, 0.1) !important;
}

.Card {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  padding: 10px;
  align-items: center;
  @media (max-width: 321px) {
    min-width: 140px;
    max-width: 140px;
  }
  min-width: 165px;
  max-width: 165px;
  width: 45%;
  left: 104px;
  top: 359px;
  align-self: center;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 13px;
  margin: 5px;
}

.StartCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 321px) {
    // min-width: 140px;
    // max-width: 140px;
  }
  // min-width: 165px;
  // max-width: 165px;
  width: 100%;
  align-self: center;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 5px;
  margin-bottom: 13px;
}

.WellDoneWrapper {
  height: 95px;
  min-height: 95px;
  margin: 0 auto;
  background: transparent;
  // border: 2px dashed #000000;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0075em;
    color: #000;
  }
}
.columnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.descriptionWrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  .successIcon {
    padding-top: 11px;
    /*animation: fadeIn 1s;*/
    display: none;
  }
  .counter {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 138%;
    /* or 17px */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0075em;

    /* 999999 */

    color: #999999;
    position: absolute;
    top: 5px;
    right: 10px;
  }
}
.categoryTitle {
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* KS_0F1619 */
  align-self: center;
  justify-self: center;
  color: #000;
  margin: 5px auto;
  // margin-bottom: 5px;
}

.mainCardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  // min-width: 45%;
  @media (max-width: 321px) {
    // min-width: 45;
  }
  min-height: 132px;
  margin: 13px auto;
  span {
    text-align: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* KS_0F1619 */

    color: #000;
    // margin-bottom: 5px;
  }
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 168px;
  @media (max-width: 321px) {
    min-width: 140px;
  }
  min-height: 110px;
  margin: 0 auto;
  span {
    text-align: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 138%;
    /* or 19px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* KS_0F1619 */

    color: #000;
    // margin-bottom: 5px;
  }
}

.AnswerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  margin-top: 5px;
  .Card {
    border: 2px dashed #000000;
    width: 45%;
    height: auto;
    min-height: 245px;
    margin-bottom: 50px;
    align-items: flex-start;
    .Question {
      // height: 80%;
      // max-width: 88%;
      padding: 5px;
      .TitleDescription {
        padding-top: 10px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        color: #000000;
      }

      .QuestionDescription {
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // max-height: calc(20vh - 5rem);
        color: #000000;
        display: flex;
        font-size: 14px;
        padding: 5px;
        justify-content: center;
        align-items: center;
        text-align: center;
        &:hover {
          overflow: auto;
          white-space: pre-wrap;
        }
      }
      .QuestionFeedback {
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          overflow: auto;
          white-space: pre-wrap;
        }
      }
    }
  }
  margin-bottom: 8rem;
}

.EndCardWrapper {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-self: center;
  justify-content: center;
  width: 100%;

  .EndCard {
    flex: 1;
    min-height: 117px;
    max-width: 117px;
    margin: 20px;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    align-self: center;
    position: relative;
    border-radius: 150px;
    justify-content: center;

    .successText {
      font-family: Open Sans;
      font-size: 30px;
      font-weight: 800;
      line-height: 41.4px;
      color: #03ce91;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .errorText {
      font-family: Open Sans;
      font-size: 30px;
      font-weight: 800;
      line-height: 41.4px;
      color: #e26c82;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .text {
      //styleName: KS_BodyRegular;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.32px;
      letter-spacing: 0.0075em;
      text-align: center;

      color: #000;
      text-transform: capitalize;
    }
  }
  .Results {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      margin-top: 53px;
      width: 40px;
      height: 40px;
    }
  }
}

.replayButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 11px 7px 13px;
  border: 1px solid #fff100;
  border-radius: 100px;
  margin: 30px auto;
  width: 202px;
  height: 44px;
  background-color: #fff100;
  font-weight: bold;
  outline: none;
  span {
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    font-style: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.0075em;
    color: #000000;
    text-transform: uppercase;
  }
  img {
    display: flex;
    align-self: center;
    justify-self: center;
    margin-left: 10px;
    width: 10px;
    height: 10px;
  }
}

.WellDone {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.0075em;
  color: #000;
  margin-bottom: 14px;
}

.Preview {
  display: flex;
  max-width: 750px;
}
.hidden {
  opacity: 0 !important;
}
@media (max-width: 768px) {
  .Preview {
    width: 100%;
    max-width: 100%;
  }
  .Card {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .Preview {
    width: 100%;
    max-width: 100%;
  }
  .Card {
    width: 337px;
    .Question {
      max-width: 100%;
    }
  }
}
@media (min-width: 768px) {
  .start {
    .Question {
      width: 100%;
      max-width: 100%;
    }
  }
}

@keyframes toUp {
  0% {
    margin-top: 0px;
  }
  50% {
    margin-top: 0px;
  }
  100% {
    margin-top: -40px;
  }
}

.fadeIn {
  animation: fadeIn 2s;
}

.fadeOut {
  animation: fadeOut 0.8s;
  height: auto;
  min-height: 132px;
  width: 80% !important;
  margin: 30px auto;
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center !important;
  align-items: center !important;
  background-color: #000000;
  left: 10% !important;
  div {
    color: #ffffff !important;
  }
}
.modalBackground {
  background: #000;
  opacity: 0.25;
  z-index: 0;
  width: 100%;
  height: 94vh;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0;
}
.modalLayout {
  width: 100%;
  height: 374px;
  max-width: 342px;
  margin: calc(50vh - 374px) auto;
  margin-top: 50px;
  gap: 0px;
  background: #f8f8f8;
  box-shadow: 0px 4px 4px 0px #00000040;
  z-index: 10;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .AnimationWrapper {
    width: 75%;
  }
  h1 {
    //styleName: Intro Regular;
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    color: #0f1619;
    margin: 10px;
  }
  span {
    //styleName: Body Regular;
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.1px;
    text-align: center;
    color: #0f1619;
    margin: 20px;
  }
}

.moveBackLeft {
  z-index: 9999999 !important;
  animation: moveBackLeft 0.8s;
  height: auto;
  min-height: 132px;
  width: 80% !important;
  margin: 30px auto;
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center !important;
  align-items: center !important;
  background-color: #000000;
  left: 10%;
  div {
    color: #ffffff !important;
  }
}

.moveBackRight {
  z-index: 9999999 !important;
  animation: moveBackRight 0.8s;
  height: auto;
  min-height: 132px;
  width: 80% !important;
  margin: 30px auto;
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center !important;
  align-items: center !important;
  background-color: #000000;
  left: 10%;
  div {
    color: #ffffff !important;
  }
}

@keyframes moveBackRight {
  0% {
    top: 0px;
    left: 10%;
    opacity: 1;
  }
  80% {
    top: -230px;
    left: -40%;
    opacity: 1;
  }
  100% {
    top: -230px;
    left: -40%;
    opacity: 1;
  }
}

@keyframes moveBackLeft {
  0% {
    top: 0px;
    right: 10%;
    opacity: 1;
  }
  80% {
    top: -230px;
    right: -95%;
    opacity: 1;
  }

  100% {
    top: -230px;
    right: -95%;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.pt5 {
  padding: 5px;
}

.p10 {
  padding: 10px;
}

.previewQuestionText {
  span {
    font-size: 7px !important;
  }
}
.fontSmall {
  font-size: 12px !important;
}

.eventsNone {
  pointer-events: none;
}
