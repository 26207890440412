.Container {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  opacity: 1;
  background-color: #fff100;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
}
.ContainerHidden {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  opacity: 0;
}
.logoWrapper {
  width: 150px;
  height: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  img {
    display: flex;
    width: 150px;
    height: auto;
    margin-bottom: 20px;
  }
  .loadingWrapper {
    display: flex;
    width: 60px;
    height: 60px;
  }
}
