.container {
  // background-image: url("./example.jpeg");
  background-color: #cccccc; /* Used if the image is unavailable */
  height: calc(100vh - 140px); /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media (min-width: 1024px) {
    // width: 1024px;
  }
}
.introductoryContainer {
  width: 100%;
  height: 125px;
  bottom: 0px;
  left: 0px;
  position: absolute;
  /* KS_FFF */

  background: #ffffff;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.25);
  // border-radius: 75px 0px 0px 0px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    max-width: 750px;
    left: inherit;
  }
}
.title {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 138%;

  letter-spacing: 0.0075em;

  color: #000;
}
.descriptionContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
}
.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin: 5px;
}
.icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;

  filter: brightness(0) saturate(100%); //Black
}
.description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 138%;
  margin-left: 5px;
  width: 90%;
  /* or 17px */

  text-align: start;
  letter-spacing: 0.0075em;

  /* KS_28353C */

  color: #000;
}
.Header {
  height: 114px;
  position: relative;
  z-index: 1;
}
.Image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 1024px) {
  .Image {
    width: 750px;
    left: inherit;
  }
  .itemContainer {
    width: 100%;
  }
}
