.drawerContainer {
  width: 100%;
  height: 100vh;
  background: transparent;
  z-index: 99999;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  transition: 500ms;
  transform: translateX(100%);
  overflow-x: none;
  scroll-behavior: unset;
  max-width: 70vw;
  position: fixed;
  transition-timing-function: ease;
  @media (min-width: 600px) {
    max-width: 500px;
  }
}
.disabled {
  color: #666666 !important;
}

.active {
  color: #000 !important;
}

.drawerContainerOpen {
  width: 100%;
  height: 100vh;
  background: transparent;
  z-index: 99999;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  overflow-x: none;
  transition: 500ms;
  transform: translateX(0px);
  max-width: 70vw;
  transition-timing-function: ease;
  @media (min-width: 600px) {
    max-width: 500px;
  }
}

.drawerMenu {
  flex: 3;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.25);
}

.overlay {
  background-color: #7185be;
  opacity: 0.5;
  transition: 300ms;
  transition-delay: 300ms;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  transition-timing-function: ease;
}

.overlayHidden {
  opacity: 0;
  transition: 300ms;
  transition-delay: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  transition-timing-function: ease;
}
.checkoutButton {
  background: #44c032;
  border-radius: 6px;
  border: 2px solid #44c032;
  outline: none;
  text-decoration: none !important;
  list-style: none;
  padding: 5px 14px 5px 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  img {
    margin-right: 20px;
    @media (max-width: 400px) {
      margin-right: 10px;
    }
  }
  span {
    font-family: "Arial, Helvetica, sans-serif";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */
    @media (max-width: 400px) {
      font-size: 12px;
    }

    letter-spacing: 0.0075em;

    /* KS_FFF */

    color: #ffffff;
  }
  &:hover {
    outline: none;
    text-decoration: none !important;
    list-style: none;
  }
  &:focus {
    outline: none;
    text-decoration: none !important;
    list-style: none;
  }
}

.menuHeader {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 138%;
  /* identical to box height, or 17px */

  letter-spacing: 0.0075em;
  text-transform: uppercase;

  /* KS_28353C */

  color: #000000;
}
.drawerHeader {
  padding: 17px;
  height: 100%;
  max-height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  background: #fff100;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.drawerItem {
  border: 0;
  border-bottom: 1px solid #e9ebec;
  padding-bottom: 13px; /* For vertical spacing */
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  margin-top: 13px;
  font-size: 14px;
  line-height: 138%;
  cursor: pointer;
  /* or 19px */

  letter-spacing: 0.0075em;

  /* KS_0F1619 */

  color: #000;
}

.logoutItem {
  border: 0;
  border-bottom: 1px solid #e9ebec;
  padding-bottom: 13px; /* For vertical spacing */
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  cursor: pointer;
  /* or 19px */

  letter-spacing: 0.0075em;

  /* KS_0F1619 */
  width: calc(100% - 34px);
  color: #000;
  position: fixed;
  bottom: 0;
  left: 0;
  margin-top: 13px;
  margin-left: 17px;
  margin-right: 17px;
  margin-bottom: 17px;
}
.selectedDrawerItem {
  color: #000000 !important;
  font-weight: bold !important;
}

.drawerContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 17px;
  height: 100%;
}

.downArrowClosed {
  transform: rotate(90deg);
}

.accordionHeader {
  width: 100%;
  display: flex;
  border: 0;
  border-bottom: 1px solid #e9ebec;
  padding-bottom: 13px; /* For vertical spacing */
  justify-content: space-between;
  align-items: space-between;
  height: 50px;
  flex-direction: row;
  span {
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    margin-top: 13px;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    letter-spacing: 0.0075em;

    /* KS_0F1619 */

    color: #000;
    width: 100%;
    height: 100%;
  }
  img {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 7.18px;
    margin-top: 13px;
    height: 4.59px;
    cursor: pointer;
  }
  div {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    padding-right: 17px;
    align-items: center;
  }
}

.accordionContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
